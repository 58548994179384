import React from 'react'
import Box from 'components/Box'
import Contact from 'components/Contact'
import Container from 'components/Container'
import Head from 'components/Head'
import Layout from 'components/Layout'
import Title4 from 'components/Title4'
import View from 'components/View'
import useEventPageVisited from 'hooks/use-event-page-visited'

export default function ContactPage() {
  useEventPageVisited('Contact')

  return (
    <Layout className="Contact" data-test-id="contact">
      <Head
        title="Contact us"
        description="Team Bold is here to help — reach out with questions or if you need support."
      />
      <View color="grey" flush>
        <Container size="medium">
          <Box color="white" size="large">
            <Title4 subtext="We’re here to help!" size="small">
              Contact us
            </Title4>
            <Contact />
          </Box>
        </Container>
      </View>
    </Layout>
  )
}
